import { FC, useState, useContext } from "react"
import { Link } from "react-router-dom"
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Paper,
  PaperProps,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useAuth0 } from "@auth0/auth0-react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import MenuIcon from "@mui/icons-material/Menu"
import { UsersSetttingsDialog } from "../shared/UserSettingsDialog"
import { useFetchCurrentUser } from "../../api/users"
import { PromoContext } from "../../App"
import { RequestChannelDialog } from "./RequestChannelDialog"
import { HubspotForm } from "@components/Nav/HubspotForm"
import CloseIcon from "@mui/icons-material/Close"

const PaperNoTabIndex = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "tabIndex",
})({})

const noStyle = {
  textDecoration: "none",
  color: "text.primary",
}
const linkButtonStyle = {
  ...noStyle,
  "&:active": noStyle,
  "&:focus": noStyle,
  "&:hover": noStyle,
}

export const TopBar: FC<{ toggleNav?: () => void }> = ({ toggleNav }) => {
  const { user, logout } = useAuth0()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [helpCenterAnchorEl, setHelpCenterAnchorEl] = useState<null | HTMLElement>(null)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const isPromo = useContext(PromoContext)
  const { data: currentUser } = useFetchCurrentUser(!isPromo)

  const [contactFormOpen, setContactFormOpen] = useState(false)
  const [requestNewChannelFormOpen, setRequestNewChannelFormOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    if (type === "help") {
      setHelpCenterAnchorEl(event.currentTarget)
    } else {
      setUserMenuAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setUserMenuAnchorEl(null)
    setHelpCenterAnchorEl(null)
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "linear-gradient(40deg, rgba(4,4,24,1) 87%, rgba(4,26,55,.88))",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ height: 64 }}>
        {toggleNav && (
          <IconButton onClick={toggleNav} sx={{ color: "common.white" }} size="large" edge="start">
            <MenuIcon />
          </IconButton>
        )}
        <Box flexGrow={1} height="100%" marginRight={2}>
          <Link to={isPromo ? "/redirect" : "/"} style={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              sx={{ maxHeight: "50%", maxWidth: "100%" }}
              src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.ai.text.and.logo.png"
            />
          </Link>
        </Box>
        <IconButton size="small" sx={{ color: "neutral.light" }} onClick={(event: any) => handleClick(event, "help")}>
          <HelpOutlineIcon sx={{ height: "20px" }} />
        </IconButton>
        {!isPromo && (
          <>
            <IconButton
              size="large"
              sx={{ color: "neutral.light" }}
              onClick={(event: any) => handleClick(event, "user")}
            >
              <AccountCircleIcon sx={{ height: "30px" }} />
            </IconButton>

            <Menu
              id="user-icon-menu"
              anchorEl={userMenuAnchorEl}
              open={userMenuAnchorEl !== null}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "user-icon-button",
              }}
            >
              <MenuItem component={Link} to="/account" sx={linkButtonStyle}>
                {user?.email}
              </MenuItem>
              <MenuItem onClick={() => setNotificationsOpen(true)}>Notifications</MenuItem>
              <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log Out</MenuItem>
              <UsersSetttingsDialog
                user={currentUser}
                open={notificationsOpen}
                onClose={() => setNotificationsOpen(false)}
              />
            </Menu>
          </>
        )}
        <Paper component="div" />
        <Menu
          id="help-menu"
          anchorEl={helpCenterAnchorEl}
          open={helpCenterAnchorEl !== null}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{ component: PaperNoTabIndex } as Partial<PaperProps<"div">>}
        >
          <MenuItem onClick={() => setContactFormOpen(true)}>Contact Us</MenuItem>
          {!isPromo && <MenuItem onClick={() => setRequestNewChannelFormOpen(true)}>Request New Channel</MenuItem>}
        </Menu>
        <Dialog open={contactFormOpen} onClose={() => setContactFormOpen(false)} fullWidth>
          <DialogTitle sx={{ overflowY: "unset" }}>Contact Us</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setContactFormOpen(false)}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={10} marginTop={1} padding={1}>
                <HubspotForm portalId="20823260" formId="c5166972-4002-4af2-a2d3-832e02c76396" region="na1" />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={requestNewChannelFormOpen} onClose={() => setRequestNewChannelFormOpen(false)} fullWidth>
          <DialogTitle sx={{ overflowY: "unset" }}>Request New Channel</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setRequestNewChannelFormOpen(false)}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={10} marginTop={1} padding={1}>
                <HubspotForm portalId="20823260" formId="193069cb-7832-4b9f-bf26-b028aaa596fb" region="na1" />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Toolbar>
      <RequestChannelDialog />
    </AppBar>
  )
}
