import { FC, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"

const getDomainClient = (env: NodeJS.ProcessEnv) => {
  const domain = env.REACT_APP_AUTH0_DOMAIN || "qa1auth.cloverleaf.ai"
  const client = env.REACT_APP_AUTH0_CLIENT_ID || "rLbgqtfkaD6xGeS1NSCQttSuRzh13dt7"
  const audience = env.REACT_APP_AUTH0_AUDIENCE || "https://rails-secure-api"

  return { domain, client, audience }
}

const Auth0ProviderWithHistory: FC = ({ children }) => {
  const { domain, client, audience } = useMemo(() => {
    return getDomainClient(process.env)
  }, [])

  const history = useHistory()

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
