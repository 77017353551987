import { FC, useEffect, useMemo } from "react"

type HubspotFormParams = {
  portalId: string
  formId: string
  region: string
}

export const HubspotForm: FC<HubspotFormParams> = ({ portalId, formId, region }) => {
  const formPlaceholderId = useMemo(() => {
    return `hubspot-form-${Date.now()}-${Math.round(Math.random() * 10000)}`
  }, [])

  const createForm = () => {
    // @ts-ignore
    window.hbspt.forms.create({
      region: region,
      portalId: portalId,
      formId: formId,
      target: `#${formPlaceholderId}`,
    })
  }

  useEffect(() => {
    // @ts-ignore
    if (!window.hbspt) {
      const hubspotScript = document.createElement("script")
      hubspotScript.src = "https://js.hsforms.net/forms/shell.js"
      document.body.appendChild(hubspotScript)

      hubspotScript.addEventListener("load", () => {
        // @ts-ignore
        if (window.hbspt) {
          createForm()
        }
      })
    } else {
      createForm()
    }
  }, [])

  return (
    <>
      <div id={formPlaceholderId}></div>
    </>
  )
}
